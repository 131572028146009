<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <slot v-if="!hover && !selected" name="item:avatar" />
    <v-list-item-avatar v-else>
      <v-simple-checkbox
        :ripple="false"
        color="primary"
        :value="selected"
        @mousedown.stop=""
        @click.stop.prevent="$emit('select')"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <slot name="item:content" />
    </v-list-item-content>
    <div v-show="hover && !isSelectionMode" class="actions-wrapper">
      <slot name="item:actions" />
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItemSelectable',
  inheritAttrs: false,
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    isSelectionMode: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    avatarColor: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style></style>
